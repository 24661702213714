<template>
  <div class="organization pa-4">
    <div class="d-flex justify-space-between mb-6">
      <div class="d-flex">
        <div class="left">
          <v-btn
            v-if="$hasRight('organizations.manageAll')"
            class="ml-2 mr-2 m_brown"
            icon
            :to="{ name: 'organizations-administration' }"
          >
            <v-icon large>mdi-arrow-left-bold-circle</v-icon>
          </v-btn>
          <div>
            <h1>Administration de {{ organization.name }}</h1>
          </div>
        </div>
        <div class="mr-8">
          <HighButtonSlot
            v-if="$hasRight('organizations.changeInfos')"
            @click="openDeleteOrganizationModale()"
            >Supprimer l'organisation</HighButtonSlot
          >
        </div>
      </div>
    </div>
    <div class="scrollable_container pa-1 pr-4">
      <v-card v-if="$hasRight('organizations.changeInfos')">
        <v-card-title>
          <h3>Informations de l'organisation</h3>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-if="isOrganizationEditMode"
            outlined
            clearable
            label="Nom"
            v-model="newOrganizationName"
          >
          </v-text-field>
          <p v-else>Nom : {{ organization.name }}</p>
          <v-checkbox
            color="#2a7221"
            v-if="
              $hasRight('organizations.manageAll') && isOrganizationEditMode
            "
            label="Organisation interne"
            v-model="newOrganizationIsInternal"
          />
          <p v-else>Interne : {{ organization.isInternal ? "oui" : "non" }}</p>
          <HighButtonSlot
            v-if="isOrganizationEditMode"
            @click="saveOrganization"
            >Valider</HighButtonSlot
          >
        </v-card-text>
        <v-card-actions right>
          <v-spacer></v-spacer>
          <v-icon
            :color="isOrganizationEditMode ? '#b80000' : '#e89005'"
            @click="isOrganizationEditMode = !isOrganizationEditMode"
            >{{ isOrganizationEditMode ? "mdi-close" : "mdi-pen" }}</v-icon
          >
        </v-card-actions>
      </v-card>
      <AgencyTable
        @refreshData="refreshViewData()"
        :agencies="agencies"
        :organization="organization"
      />
      <UserTable
        @refreshData="refreshViewData()"
        :users="organizationUsers"
        :organization="organization"
      />
      <CampaignTable :campaigns="campaigns" />
    </div>
    <OrganizationDeleteModale
      v-if="isDeleteOrgaModaleDisplayed"
      :organization="organization"
      @closePopin="closeOrganizationDeleteModale()"
    />
  </div>
</template>

<script>
import UserTable from "@/components/administration/dashboard/User.table.vue";
import AgencyTable from "@/components/administration/dashboard/Agency.table.vue";
import CampaignTable from "@/components/administration/dashboard/Campaign.table.vue";
import OrganizationDeleteModale from "@/components/entities/organization/DeleteOrganization.modal";

export default {
  components: {
    AgencyTable,
    UserTable,
    CampaignTable,
    OrganizationDeleteModale
  },
  data() {
    return {
      isDeleteOrgaModaleDisplayed: false,
      isOrganizationEditMode: false,

      organization: {
        name: ""
      },
      newOrganizationName: "",
      newOrganizationIsInternal: false,

      organizationUsers: [],
      agencies: [],
      campaigns: []
    };
  },
  methods: {
    openDeleteOrganizationModale() {
      this.isDeleteOrgaModaleDisplayed = true;
    },
    closeOrganizationDeleteModale() {
      this.isDeleteOrgaModaleDisplayed = false;
    },
    async saveOrganization() {
      await this.$api.organizations.edit(this.organization.id, {
        name: this.newOrganizationName,
        isInternal: this.newOrganizationIsInternal
      });
      this.refreshViewData();
      this.isOrganizationEditMode = false;
    },
    async getAgencies() {
      this.agencies = await this.$api.agencies.findByOrganization(
        this.$route.params.id
      );
    },
    async getOrganization() {
      this.organization = await this.$api.organizations.findById(
        this.$route.params.id
      );
      this.newOrganizationName = this.organization.name;
      this.newOrganizationIsInternal = this.organization.isInternal;
    },
    async getOrganizationUsers() {
      const users = await this.$api.users.findByOrganization(
        this.$route.params.id
      );

      this.organizationUsers = users.map(user => {
        const userRoleIndex = user.roles.findIndex(
          role => role === "ROLE_USER"
        );

        user.roles.splice(userRoleIndex, 1);

        return user;
      });
    },
    async getCampaigns() {
      const rawCampaigns = await this.$api.campaigns.findByUser(
        this.$store.state.user.current.userId,
        true
      );

      this.campaigns = this.organization.isInternal
        ? rawCampaigns.filter(
            campaign =>
              campaign.workspace.agency.organization.id === this.organization.id
          )
        : rawCampaigns.filter(
            campaign =>
              campaign.workspace.customerAgency.organization.id ===
              this.organization.id
          );
    },
    refreshViewData() {
      if (undefined === this.$store.state.user.current?.userId)
        return setTimeout(this.refreshViewData, 200);
      if (!this.$hasRight("organizations.manageOne"))
        this.$router.push("/workspaces");
      this.getOrganization();
      this.getAgencies();
      this.getCampaigns();
      this.getOrganizationUsers();
    }
  },
  mounted() {
    this.refreshViewData();
  }
};
</script>

<style lang="scss">
@import "../../assets/scss/_variables.scss";

.organization {
  height: 100vh;
  display: flex;
  flex-direction: column;
  .d-flex,
  .d-flex .left {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .d-flex {
    justify-content: space-between;
  }
}

.actionBtn {
  cursor: pointer;
  margin: 5px;
}
.TableCard {
  margin: 25px 0;

  .v-data-footer .v-btn > .v-btn__content .v-icon {
    color: rgb(150, 150, 150) !important;
  }

  .search-field {
    margin-right: 10px;
    width: 24%;
  }
  .search-row {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .add-field {
    display: flex;
    flex-flow: row-reverse;
    flex-grow: 1;
  }

  .table-actions {
    display: flex;
    justify-content: center;
    align-items: center;

    .table-action {
      cursor: pointer;
      margin: 5px;
    }
  }
}
</style>
