<template>
  <v-card class="TableCard" flat v-if="$hasRight('campaigns.manageAll')">
    <v-card-title class="pl-1 pr-1">
      <h3>Campagnes</h3>
    </v-card-title>
    <v-card-text class="pl-1 pr-2">
      <div class="search-row">
        <div class="search-field">
          <v-text-field
            v-model="campaignSearch"
            append-icon="mdi-magnify"
            :label="$t('search_campaign')"
            single-line
            hide-details
            clearable
            dense
          ></v-text-field>
        </div>
      </div>
      <v-data-table
        :loading="campaigns === []"
        :headers="campaignsHeaders"
        :items="campaigns"
        :items-per-page="5"
        :hide-default-footer="campaigns.length < 6"
        :search="campaignSearch"
        class="bordered"
        ><template v-slot:item.edit="{ item }">
          <div class="table-actions">
            <v-tooltip top v-if="$hasRight('campaigns.accessAdminEdit')">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  @click="openCampaignEditionView(item)"
                  class="table-action"
                  color="#e89005"
                  v-bind="attrs"
                  v-on="on"
                  >mdi-pen</v-icon
                >
              </template>
              <span>Modifier les droits des utilisateurs</span>
            </v-tooltip>
          </div>
        </template>
        <template v-slot:item.type="{ item }">
          <v-chip :color="getCampaignColor(item.type)">{{
            $t(item.type)
          }}</v-chip>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ["campaigns"],
  components: {},
  data() {
    return {
      campaignSearch: "",

      campaignsHeaders: [
        { text: "Nom", value: "name" },
        { text: "Description", value: "description" },
        { text: "Projet", value: "workspace.name" },
        { text: "Agence interne", value: "workspace.agency.name" },
        { text: "Client", value: "workspace.customerAgency.name" },
        { text: "Modifier les droits", value: "edit", align: "center" }
      ]
    };
  },
  methods: {
    openCampaignEditionView(campaign) {
      this.$router.push({
        name: "campaign-administration",
        params: { id: campaign.id }
      });
    },
    getCampaignColor(campaignType) {
      return campaignType == "coring" ? "#ffbd00" : "#ff2e00";
    }
  }
};
</script>

<style lang="scss" scoped>
.search-field {
  margin-right: 0 !important;
}
.search-row {
  height: 36px;
}
.bordered {
  border: thin solid rgba(0, 0, 0, 0.12);
}
</style>
