var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"TableCard",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"pl-1 pr-1"},[_c('h3',[_vm._v("Agences")])]),_c('v-card-text',{staticClass:"pl-1 pr-2"},[_c('div',{staticClass:"search-row"},[_c('div',{staticClass:"search-field"},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search_agency'),"single-line":"","hide-details":"","clearable":"","dense":""},model:{value:(_vm.agencySearch),callback:function ($$v) {_vm.agencySearch=$$v},expression:"agencySearch"}})],1),_c('div',{staticClass:"add-field"},[(_vm.$hasRight('agencies.create'))?_c('medium-button-slot',{on:{"click":function($event){return _vm.openAgencyCreationModal()}}},[_vm._v(_vm._s(_vm.$t("add_agency")))]):_vm._e()],1)]),_c('v-data-table',{staticClass:"bordered",attrs:{"loading":_vm.agencies === [],"headers":_vm.agenciesHeaders,"items":_vm.agencies,"items-per-page":5,"search":_vm.agencySearch,"hide-default-footer":_vm.agencies.length < 6},scopedSlots:_vm._u([{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table-actions"},[(_vm.$hasRight('agencies.edit'))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"table-action",attrs:{"color":"#e89005"},on:{"click":function($event){return _vm.openAgencyEditionModal(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-pen")])]}}],null,true)},[_c('span',[_vm._v("Modifier l'agence")])]):_vm._e(),(_vm.$hasRight('agencies.delete'))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"table-action",attrs:{"color":"#b80000"},on:{"click":function($event){return _vm.openAgencyDeletionModal(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-delete-forever")])]}}],null,true)},[_c('span',[_vm._v("Supprimer l'agence")])]):_vm._e()],1)]}}])})],1),(_vm.isAgencyDeleteModaleOpen)?_c('AgencyDeleteModale',{attrs:{"agency":_vm.currentAgency,"agencies":_vm.agencies},on:{"closePopin":function($event){_vm.isAgencyDeleteModaleOpen = false},"agencyDeleted":function($event){return _vm.$emit('refreshData')}}}):_vm._e(),(_vm.isAgencyEditModaleOpen)?_c('AgencyEditModale',{attrs:{"agency":_vm.currentAgency},on:{"closePopin":function($event){_vm.isAgencyEditModaleOpen = false},"agencyEdited":function($event){return _vm.$emit('refreshData')}}}):_vm._e(),(_vm.isAgencyCreateModaleOpen)?_c('AgencyCreateModale',{attrs:{"organization":_vm.organization},on:{"closePopin":function($event){_vm.isAgencyCreateModaleOpen = false},"agencyCreated":function($event){return _vm.$emit('refreshData')}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }