<template>
  <div class="DeletePopin">
    <v-card flat outlined>
      <v-card-title class="center justify-center" align-items>
        <h3>Confirmer la suppression de l'agence {{ agency.name }} ?</h3>
      </v-card-title>
      <v-autocomplete
        label="Agence de repli"
        :items="selectableAgencies"
        item-text="name"
        item-value="id"
        v-model="fallbackAgencyId"
        outlined
      ></v-autocomplete>
      <v-card-text class="card_text">
        <p>Êtes-vous sûr de vouloir supprimer l'agence {{ agency.name }} ?</p>
        <p>Cette action est irréversible</p>
      </v-card-text>
      <v-card-actions>
        <LowButtonSlot @click="closePopin"> Annuler </LowButtonSlot>
        <v-spacer></v-spacer>
        <HighButtonSlot @click="confirmAgencyDeletion">
          Valider
        </HighButtonSlot>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["agency", "agencies"],
  data() {
    return {
      fallbackAgencyId: null,
    };
  },
  computed: {
    selectableAgencies() {
      return this.agencies.filter((agency) => agency.id !== this.agency.id);
    },
  },
  methods: {
    closePopin() {
      this.$emit("closePopin");
    },
    async confirmAgencyDeletion() {
      await this.$api.agencies.delete(this.agency.id, this.fallbackAgencyId);
      this.$emit("agencyDeleted");
      this.closePopin();
    },
  },
  mounted() {
    this.$root.$on("closeCurrent", () => this.$emit("closePopin"));
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_extends.scss";

.DeletePopin {
  @extend %popinView;

  &__card p {
    text-align: left !important;
    margin: 0 0 20px 0 !important;
  }

  .buttonsContainer button {
    margin: 5px;
  }
}
</style>
