<template>
  <div class="CreatePopin">
    <v-card flat outlined>
      <v-card-title class="center justify-center" align-items>
        <h3>Modifier une agence</h3>
      </v-card-title>
      <v-form
        class="form"
        ref="form"
        v-model="isFormValid"
        @submit.prevent="confirmModifications"
      >
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              autofocus
              tabindex="1"
              outlined
              label="Nom"
              :rules="[$rules.required]"
              clearable
              v-model="newAgency.name"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              label="Email"
              tabindex="2"
              type="email"
              :rules="[$rules.required, $rules.validEmail]"
              clearable
              outlined
              v-model="newAgency.email"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
            v-if="false === agency.organization.isInternal"
          >
            <v-autocomplete
              tabindex="3"
              outlined
              label="Commercial"
              :items="commercials"
              clearable
              item-value="id"
              item-text="email"
              v-model="commercialId"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              tabindex="4"
              outlined
              clearable
              label="Pays"
              :rules="[$rules.required]"
              v-model="newAgency.address.country"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              tabindex="5"
              outlined
              clearable
              :rules="[$rules.required]"
              label="Rue"
              v-model="newAgency.address.street"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              tabindex="6"
              outlined
              label="Détail"
              clearable
              v-model="newAgency.address.detail"
            ></v-text-field>
          </v-col> </v-row
        ><v-row>
          <v-col cols="12" md="6">
            <v-text-field
              tabindex="7"
              outlined
              clearable
              label="Code postal"
              :rules="[$rules.required, $rules.validZipCode]"
              v-model="newAgency.address.zipcode"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              tabindex="8"
              label="Ville"
              clearable
              :rules="[$rules.required]"
              outlined
              v-model="newAgency.address.city"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              tabindex="9"
              outlined
              label="Téléphone"
              v-model="newAgency.contact.phone"
              :rules="[rules.oneFilled]"
              @input="$refs.form.validate()"
              clearable
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              tabindex="10"
              outlined
              label="Mobile"
              clearable
              @input="$refs.form.validate()"
              :rules="[rules.oneFilled]"
              v-model="newAgency.contact.mobile"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="6"
            v-if="false === agency.organization.isInternal"
          >
            <v-autocomplete
              outlined
              tabindex="11"
              label="Système géoréférentiel"
              :items="projectingSystems"
              :rules="[$rules.required]"
              item-text="name"
              item-value="val"
              v-model="agency.projectingSystem"
              clearable
              placeholder="L93"
            ></v-autocomplete>
          </v-col>
          <v-col v-else cols="12" md="6">
            <v-text-field
              tabindex="11"
              outlined
              :rules="[$rules.required]"
              label="Nom raccourci"
              clearable
              v-model="agency.shortName"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <editable-list-field
              v-model="agencyProviders"
              label="Prestataire(s)"
              :item-text="(item) => item.provider.name"
              :empty-item="agencyProvider"
              modale-title="Paramétrer le prestataire"
            >
              <template v-slot:modale="agencyProvider">
                <v-form>
                  <v-autocomplete
                    label="Prestataire"
                    :items="providers"
                    item-text="name"
                    :item-value="(item) => item"
                    v-model="agencyProvider.newItem.provider"
                    clearable
                    tabindex="100"
                    autofocus
                    outlined
                    :rules="[$rules.required]"
                  >
                  </v-autocomplete>
                  <v-text-field
                    label="Nom utilisateur"
                    v-model="agencyProvider.newItem.username"
                    clearable
                    outlined
                    :rules="[$rules.required]"
                    tabindex="101"
                  ></v-text-field>
                  <v-text-field
                    label="Mot de passe"
                    v-model="agencyProvider.newItem.password"
                    clearable
                    :rules="[$rules.required]"
                    outlined
                    tabindex="102"
                  ></v-text-field>
                </v-form>
              </template>
            </editable-list-field>
          </v-col>
        </v-row>
        <v-card-actions>
          <LowButtonSlot @click="closePopin"> Annuler </LowButtonSlot>
          <v-spacer></v-spacer>
          <HighButtonSlot :_disabled="!isFormValid" _type="submit">
            Valider
          </HighButtonSlot>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["agency"],
  data() {
    return {
      isFormValid: true,
      newAgency: { ...this.agency },
      commercials: [],
      commercialId: null,
      agencyProviders: [],
      previousProviders: [],
      agencyProvider: {
        id: null,
        username: "",
        password: "",
        agency: {
          id: null,
          name: null,
        },
        provider: {
          id: null,
          name: null,
        },
      },
      providers: [],
      rules: {
        oneFilled: () => {
          return (
            this.newAgency.contact?.phone?.length === 10 ||
            this.newAgency.contact?.mobile?.length === 10 ||
            "au moins un numéro de téléphone requis"
          );
        },
      },
      projectingSystems: [
        {
          name: "World Geodetic system 1984",
          val: "WGS84",
        },
        {
          name: "Lambert 93",
          val: "L93",
        },
        {
          name: "Conique conforme 44",
          val: "CC44",
        },
        {
          name: "Conique conforme 45",
          val: "CC45",
        },
        {
          name: "Conique conforme 49",
          val: "CC49",
        },
      ],
    };
  },
  methods: {
    async getProviders() {
      this.providers = await this.$api.providers.findAll();
    },
    isValidForm() {
      return (
        this.newAgency.name.length > 2 &&
        this.newAgency.address.street.length > 2 &&
        this.newAgency.address.zipcode.length > 2 &&
        this.newAgency.address.country.length > 0 &&
        (this.newAgency.contact.phone.length === 10 ||
          this.newAgency.contact.mobile.length === 10) &&
        this.newAgency.email.length > 0
      );
    },
    async getAgencyProviders() {
      this.agencyProviders = await this.$api.agencies.getAgencyProviders(
        this.agency.id
      );
      this.previousProviders = JSON.parse(JSON.stringify(this.agencyProviders));
    },
    async getCommercials() {
      this.commercials = await this.$api.users.findByJob("commmercial");
    },
    closePopin() {
      this.$emit("closePopin");
    },
    async confirmModifications() {
      if (!this.isValidForm()) return console.error("invalid form");
      await this.$api.agencies.edit(this.agency.id, {
        zipCode: this.agency.address.zipcode,
        name: this.agency.name,
        email: this.agency.email,
        projectingSystem: this.newAgency.projectingSystem,
        shortName: this.newAgency.shortName,
        commercialId: this.commercialId === 0 ? null : this.commercialId,
        ...this.newAgency.contact,
        ...this.newAgency.address,
      });

      this.agencyProviders.forEach((ap) => {
        if (null === ap.id)
          this.$api.agencies.addProviderToAgency(
            this.agency.id,
            ap.provider.id,
            ap
          );
        else
          this.$api.agencies.editAgencyProvider(
            this.agency.id,
            ap.provider.id,
            ap
          );
      });

      this.previousProviders.forEach((ap) => {
        const index = this.agencyProviders.findIndex((ap2) => ap2.id === ap.id);

        if (-1 === index)
          this.$api.agencies.removeProviderFromAgency(
            ap.agency.id,
            ap.provider.id
          );
      });

      this.$emit("agencyEdited");
      this.closePopin();
    },
  },
  mounted() {
    this.$root.$on("closeCurrent", () => this.$emit("closePopin"));
    this.commercialId = this.newAgency.commercial?.id || 0;
    this.getCommercials();
    this.getProviders();
    this.getAgencyProviders();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_extends.scss";

.CreatePopin {
  @extend %popinView;

  &__card p {
    text-align: left !important;
    margin: 0 0 20px 0 !important;
  }

  .buttonsContainer button {
    margin: 5px;
  }
}
</style>
