<template>
  <v-card class="TableCard" flat>
    <v-card-title class="pl-1 pr-1">
      <h3>Agences</h3>
    </v-card-title>
    <v-card-text class="pl-1 pr-2">
      <div class="search-row">
        <div class="search-field">
          <v-text-field
            v-model="agencySearch"
            append-icon="mdi-magnify"
            :label="$t('search_agency')"
            single-line
            hide-details
            clearable
            dense
          ></v-text-field>
        </div>
        <div class="add-field">
          <medium-button-slot
            v-if="$hasRight('agencies.create')"
            @click="openAgencyCreationModal()"
            >{{ $t("add_agency") }}</medium-button-slot
          >
        </div>
      </div>
      <v-data-table
        :loading="agencies === []"
        :headers="agenciesHeaders"
        :items="agencies"
        :items-per-page="5"
        :search="agencySearch"
        :hide-default-footer="agencies.length < 6"
        class="bordered"
        ><template v-slot:item.edit="{ item }">
          <div class="table-actions">
            <v-tooltip top v-if="$hasRight('agencies.edit')">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  @click="openAgencyEditionModal(item)"
                  class="table-action"
                  color="#e89005"
                  v-bind="attrs"
                  v-on="on"
                  >mdi-pen</v-icon
                >
              </template>
              <span>Modifier l'agence</span>
            </v-tooltip>
            <v-tooltip top v-if="$hasRight('agencies.delete')">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  @click="openAgencyDeletionModal(item)"
                  class="table-action"
                  color="#b80000"
                  v-bind="attrs"
                  v-on="on"
                  >mdi-delete-forever</v-icon
                >
              </template>
              <span>Supprimer l'agence</span>
            </v-tooltip>
          </div>
        </template></v-data-table
      >
    </v-card-text>
    <AgencyDeleteModale
      v-if="isAgencyDeleteModaleOpen"
      :agency="currentAgency"
      :agencies="agencies"
      @closePopin="isAgencyDeleteModaleOpen = false"
      @agencyDeleted="$emit('refreshData')"
    />
    <AgencyEditModale
      v-if="isAgencyEditModaleOpen"
      :agency="currentAgency"
      @closePopin="isAgencyEditModaleOpen = false"
      @agencyEdited="$emit('refreshData')"
    />
    <AgencyCreateModale
      v-if="isAgencyCreateModaleOpen"
      :organization="organization"
      @closePopin="isAgencyCreateModaleOpen = false"
      @agencyCreated="$emit('refreshData')"
    />
  </v-card>
</template>

<script>
import AgencyCreateModale from "../../entities/agency/CreateAgency.modal.vue";
import AgencyEditModale from "../../entities/agency/EditAgency.modal.vue";
import AgencyDeleteModale from "../../entities/agency/DeleteAgency.modal.vue";

export default {
  props: ["agencies", "organization"],
  components: {
    AgencyDeleteModale,
    AgencyCreateModale,
    AgencyEditModale
  },
  data() {
    return {
      agenciesHeaders: [
        { text: "Nom", value: "name" },
        { text: "Email", value: "email" },
        { text: "Voie", value: "address.street" },
        { text: "Ville", value: "address.city" },
        { text: "CP", value: "address.zipcode" },
        { text: "Modifier", value: "edit", align: "center" }
      ],

      agencySearch: "",
      currentAgency: {},

      isAgencyDeleteModaleOpen: false,
      isAgencyEditModaleOpen: false,
      isAgencyCreateModaleOpen: false
    };
  },
  methods: {
    openAgencyEditionModal(agency) {
      this.currentAgency = agency;
      this.isAgencyEditModaleOpen = true;
    },
    openAgencyDeletionModal(agency) {
      this.currentAgency = agency;
      this.isAgencyDeleteModaleOpen = true;
    },
    openAgencyCreationModal() {
      this.isAgencyCreateModaleOpen = true;
    }
  }
};
</script>

<style lang="scss">
@import "../../../assets/scss/variables";

.bordered {
  border: thin solid rgba(0, 0, 0, 0.12);
}
</style>
